import React from "react";
import LayoutContainer from "../containers";
import { SEO, Container } from "../components/elements";
import { ResponsiveTitle1, ResponsiveTitle2 } from "../components/styled";
import BlogPostPreviewGrid from "../components/BlogPostPreviewGrid";

const GenericPostPreview = ({ title, postNodes, keywords, description }) => {
  return (
    <LayoutContainer>
      <SEO title={title} keywords={keywords} description={description} />
      <Container>
        <ResponsiveTitle1>{title}</ResponsiveTitle1>
        {postNodes && postNodes.length > 0 ? (
          <BlogPostPreviewGrid nodes={postNodes} />
        ) : (
          <ResponsiveTitle2>More content coming soon.</ResponsiveTitle2>
        )}
      </Container>
    </LayoutContainer>
  );
};

export default GenericPostPreview;
