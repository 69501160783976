import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../helpers";

import { GraphQLError } from "../components/elements";
import Layout from "../containers";
import GenericPostPreview from "./GenericPostPreview";

export const query = graphql`
  query TagPageQuery($tag: String) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        tags: { elemMatch: { tag: { title: { in: [$tag] } } } }
        isBackgroundPost: { ne: true }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          tags {
            tag {
              title
            }
          }
        }
      }
    }
    details: sanityTag(title: { eq: $tag }) {
      title
      description
      keywords
    }
  }
`;

const TagPage = (props) => {
  const { data, errors, pageContext } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const { description, keywords } = data && data.details;
  const { tag } = pageContext;

  return (
    <GenericPostPreview
      title={tag}
      postNodes={postNodes}
      keywords={keywords}
      description={description}
    />
  );
};

export default TagPage;
